import { type AxiosResponse } from 'axios'
import BaseService from '@/services/base-service'
import type { IPagination, ISort } from '~/stores/types/app'

export default class JobsService extends BaseService {
  fetchJobs = async (): Promise<IJob[]> => {
    const response: AxiosResponse<IJob[]> = await this.http.get(
      '/api-internal/v1/blanc/job/',
    )
    return response.data
  }

  fetchPaginatedJobRuns = async (
    pagination: IPagination,
    sort: ISort,
  ): Promise<IPaginatedJobRun> => {
    const response: AxiosResponse<IPaginatedJobRun> = await this.http.get(
      `/api-internal/v1/blanc/job-run/?limit=${pagination.limit}&offset=${pagination.offset}&ordering=${(sort.sortBy === 'asc' ? '' : '-') + sort.field}`,
    )
    return response.data
  }

  fetchJobRunsByName = async (jobName?: string): Promise<IJobRun[]> => {
    const response: AxiosResponse<IJobRun[]> = await this.http.get(
      `/api-internal/v1/blanc/job-run/?job__name=${jobName}`,
    )
    return response.data
  }

  fetchJobRunReport = async (id: string): Promise<IReport> => {
    const response: AxiosResponse<IReport> = await this.http.get(
      `/api-internal/v1/blanc/job-run/${id}/report/`,
    )
    return response.data
  }

  fetchJobRunsAggregatecounts = async (): Promise<IJobRunAggregateData[]> => {
    const response: AxiosResponse<IJobRunAggregateData[]> = await this.http.get(
      '/api-internal/v1/blanc/job-run/aggregatecounts/',
    )
    return response.data
  }
}

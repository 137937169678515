import { defineStore } from 'pinia'
import type {
  IJob,
  IJobRun,
  IJobRunAggregateData,
  IPaginatedJobRun,
} from './types/jobs'
import type { IDatasetReport, IReport } from './types/report'
import type { IPagination, ISort } from './types/app'
import JobsService from '~/services/jobs-service'
import { JobsView } from '@/utils/enums'

export const useJobsStore = defineStore('jobsStore', () => {
  const jobsService = new JobsService()
  // State
  const jobs: Ref<IJob[]> = ref([])
  const jobRuns: Ref<IJobRun[]> = ref([])
  const paginatedJobRuns: Ref<IPaginatedJobRun> = ref({} as IPaginatedJobRun)
  const jobRunReport: Ref<IReport> = ref({} as IReport)
  const jobRunsAggregateData: Ref<IJobRunAggregateData[]> = ref([])
  const jobsPageView = ref(JobsView.Jobs)

  // Mutations
  const updateJobs = (value: IJob[] = []) => {
    jobs.value = value
  }

  const updateJobRuns = (value: IJobRun[] = []) => {
    jobRuns.value = value
  }

  const updatePaginatedJobRuns = (
    value: IPaginatedJobRun = {} as IPaginatedJobRun,
  ) => {
    paginatedJobRuns.value = value
  }

  const updateJobRunReport = (value: IReport = {} as IReport) => {
    if (value && value.datasets) {
      // Calculating the value difference and sorting it based on the same
      // Performing this computation in store as we need this data to highlight
      // the Dataset Name in the Job report view
      value.datasets.forEach((dataset: IDatasetReport) => {
        dataset.failed_checks = dataset.failed_checks
          .map((failedCheck: IFailedCheck) => ({
            ...failedCheck,
            valueDifference: HelperMethods.valueDifference(
              failedCheck.expected_value,
              failedCheck.actual_value,
              5,
            ),
          }))
          .sort((a, b) => Number(b.valueDifference) - Number(a.valueDifference))
      })
    }
    jobRunReport.value = value
  }

  const setActiveJobsView = (activeVIew: JobsView) => {
    jobsPageView.value = activeVIew
  }

  const updateJobRunsAggregateData = (
    aggregateData: IJobRunAggregateData[] = [],
  ) => {
    jobRunsAggregateData.value = aggregateData.reverse()
  }

  // Actions
  const fetchJobs = async () => {
    updateJobs()
    await jobsService.fetchJobs().then((res: IJob[]) => {
      updateJobs(res)
    })
  }

  const fetchPaginatedJobRuns = async (
    pagination: IPagination,
    sort: ISort,
  ) => {
    updateJobRuns()
    await jobsService
      .fetchPaginatedJobRuns(pagination, sort)
      .then((res: IPaginatedJobRun) => {
        updatePaginatedJobRuns(res)
      })
  }

  const fetchJobRunsByName = async (jobName: string) => {
    updateJobRuns()
    await jobsService.fetchJobRunsByName(jobName).then((res: IJobRun[]) => {
      updateJobRuns(res)
    })
  }

  const fetchJobRunReport = async (id: string) => {
    updateJobRunReport()
    await jobsService.fetchJobRunReport(id).then((res: IReport) => {
      updateJobRunReport(res)
    })
  }

  const fetchJobRunsAggregatecounts = async () => {
    updateJobRunsAggregateData()
    await jobsService
      .fetchJobRunsAggregatecounts()
      .then((res: IJobRunAggregateData[]) => {
        updateJobRunsAggregateData(res)
      })
  }

  return {
    jobs,
    jobRuns,
    jobRunReport,
    jobsPageView,
    jobRunsAggregateData,
    paginatedJobRuns,
    updateJobs,
    updateJobRuns,
    updatePaginatedJobRuns,
    setActiveJobsView,
    fetchJobs,
    fetchPaginatedJobRuns,
    fetchJobRunReport,
    fetchJobRunsAggregatecounts,
    fetchJobRunsByName,
  }
})
